import axios from "Lib/Axios";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { publicRoutes } from "routes/routes";
// @ts-ignore
import { Helmet } from "react-helmet-async";
import { showDanger, showSuccess } from "utilities/misc";

const Verify: React.FC = () => {
  const navigate = useNavigate();
  const username = new URLSearchParams(useLocation().search).get("username");

  if (!username) navigate(publicRoutes.home);

  const [user, setUser] = useState<any>({});

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_SOCKET_URL}/refer?username=${username}`)
      .then(({ data }) => {
        if (data?.data?.firstName) {
          setUser(data?.data);
        }
      })
      .catch((e) => {
        console.log(e);
        navigate(publicRoutes.home);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const config = {
    first_name: user?.firstName,
    last_name: user?.lastName,
    email: user?.email,
    merchant_key: "live_pk_7122f94b3b60041eddc900b87dd3153b1099321",
    user_ref: user?.email,
    is_test: false, //set this to through for a test
    config_id: "2d418b12-d2e9-41c2-a82c-02db526217e3", //optional
    callback: (response: any) => {
      if (response.code === "00") {
        // success
        showSuccess(
          "Your verification was submitted successfully. You can now go back to the app."
        );
      } else if (response.code === "01") {
        // error
        showDanger(
          "Your verification was not successful, please try again or contact support"
        );
      } else {
        console.log(response);
      }
    },
  };

  function verifyKYC() {
    // @ts-ignore
    window.IdentityKYC.verify(config);
  }

  return (
    <>
      <Helmet>
        <script src="https://js.prembly.com/v1/inline/widget.js"></script>
      </Helmet>
      <div className="flex items-center justify-center h-screen px-3 bg-gray-100">
        <div className="py-10 px-7 md:px-10 bg-white rounded-xl shadow-xl drop-shadow-lg flex flex-col items-center md:max-w-[450px]">
          <img
            className="object-contain md:w-[110px] w-[100px]"
            alt="Bitoshi.africa logo"
            src={`/images/bitoshi-logo-black.png`}
          />

          <div className="flex flex-col mt-12 gap-y-5">
            <p>
              You are being redirected to a third party site to finish up your
              account verification.
            </p>
            <p>
              Once verification is done, you would be automatically redirected
              to the Bitoshi app.
            </p>
            <p>
              Be rest assured that your data is safe and protected from any
              unauthorized activity.
            </p>
            <p>
              If you experience any issues, please contact the support team.
            </p>
          </div>

          <div className="flex items-center justify-center w-full mt-10">
            <button
              onClick={verifyKYC}
              className="bg-black text-white py-2.5 px-14 rounded-lg flex items-center justify-center w-full md:w-auto"
            >
              Verify Now
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Verify;
