import useGetVendor from "hooks/useGetVendor";
import { memo, useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { vendorRoutes } from "routes/routes";
import { SocketContext } from "socket";
import { logout } from "utilities/network";

const VendorSidebar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const { vendor } = useGetVendor();
  const socket = useContext(SocketContext);
  const [transaction, setTransaction] = useState({
    deposit: 0,
    withdrawal: 0,
  });

  const subscriptionType = `dashboardTransactions`;
  const updateType = `updateAvailable`;

  const handleIncomingTransactions = useCallback((trns: any) => {
    setTransaction(trns);
  }, []);

  const askForTransactions = useCallback(() => {
    socket.emit(subscriptionType);
  }, [socket, subscriptionType]);

  useEffect(() => {
    if (vendor.id) {
      socket.connect();
      askForTransactions();

      socket.on(subscriptionType, handleIncomingTransactions);
      socket.on(updateType, askForTransactions);
    }

    return () => {
      socket.off(subscriptionType, handleIncomingTransactions);
      socket.off(updateType, askForTransactions);
      socket.disconnect();
    };
  }, [
    handleIncomingTransactions,
    socket,
    vendor.id,
    subscriptionType,
    askForTransactions,
    updateType,
  ]);

  return (
    <div className="w-full">
      <nav className="relative">
        {isOpen ? (
          <div
            id="nav"
            className="absolute z-10 w-full h-screen overflow-y-hidden font-light bg-gray-400 shadow-lg bg-opacity-60"
            onClick={() => setIsOpen((p) => !p)}
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className="w-9/12 h-full py-6 bg-white"
            >
              <div className="flex items-center justify-between w-full">
                <img
                  className="ml-5 w-[18px] h-auto object-contain"
                  src="/images/logo-small.png"
                  alt="Bitoshi"
                  onClick={() => navigate(vendorRoutes.dashboard)}
                />
                <button
                  onClick={() => setIsOpen((p) => !p)}
                  className="mr-5"
                  id="hamburger"
                  type="button"
                >
                  <i className="text-lg fa fa-bars"></i>
                </button>
              </div>
              <ul className="w-full pl-5 text-left mt-14">
                <li className="w-full mb-8">
                  <button
                    type="button"
                    className="block w-full text-sm font-light text-black text-opacity-70"
                    onClick={() => navigate(vendorRoutes.dashboard)}
                  >
                    <i className="mr-3 fa fa-cube"></i> Dashboard
                  </button>
                </li>

                <li className="w-full mb-8">
                  <button
                    type="button"
                    className="block w-full text-sm font-light text-black text-opacity-70"
                    onClick={() => navigate(vendorRoutes.profile)}
                  >
                    <i className="mr-3 fa fa-user-alt"></i> Profile
                  </button>
                </li>

                <li className="flex items-center content-between w-full pr-5 mb-8">
                  <button
                    type="button"
                    className="block w-full text-sm font-light text-black text-opacity-70"
                    onClick={() => navigate(vendorRoutes.deposits)}
                  >
                    <i className="mr-4 fa fa-long-arrow-alt-down"></i> Deposits
                  </button>
                  {transaction.deposit > 0 && <DotMemo />}
                </li>

                <li className="flex items-center content-between w-full pr-5 mb-8">
                  <button
                    type="button"
                    className="block w-full text-sm font-light text-black text-opacity-70"
                    onClick={() => navigate(vendorRoutes.withdrawals)}
                  >
                    <i className="mr-4 fa fa-long-arrow-alt-up"></i>
                    Withdrawals
                  </button>
                  {transaction.withdrawal > 0 && <DotMemo />}
                </li>

                <li className="absolute mb-5 bottom-10">
                  <button
                    className="px-16 py-2 text-sm font-normal text-red-500 border border-red-500 rounded-lg"
                    onClick={logout}
                  >
                    Sign Out
                  </button>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <div className="relative flex items-center justify-between px-5 py-6 bg-white">
            <img
              className="w-[18px] h-auto object-contain"
              src="/images/logo-small.png"
              alt="Bitoshi"
            />
            <button
              onClick={() => setIsOpen((p) => !p)}
              className=""
              id="hamburger"
              type="button"
            >
              <i className="text-lg fa fa-bars"></i>
            </button>
          </div>
        )}
      </nav>
    </div>
  );
};

const Dot: React.FC = () => (
  <span className="bg-red-500 inline-block w-1.5 h-1.5 rounded-full"></span>
);
const DotMemo = memo(Dot);

export default VendorSidebar;
