import { useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { publicRoutes } from "routes/routes";
// @ts-ignore

const MobileVerify: React.FC = () => {
  const navigate = useNavigate();
  const ref = useRef<any>(null);
  const username = new URLSearchParams(useLocation().search).get("username");

  if (!username) navigate(publicRoutes.home);

  // const [user, setUser] = useState<any>({});

  // useEffect(() => {
  //   axios
  //     .get(`${process.env.REACT_APP_SOCKET_URL}/refer?username=${username}`)
  //     .then(({ data }) => {
  //       if (data?.data?.firstName) {
  //         setUser(data?.data);
  //       }
  //     })
  //     .catch((e) => {
  //       console.log(e);
  //       navigate(publicRoutes.home);
  //     });
  //   // eslint-disable-next-line
  // }, []);

  // const MERCHANT_KEY = "70n7gbhupozbx7lgtg0t:jJBw-D-twNBzwYxTKlarifPM5hk";
  // const IDENTITYPASS_CONFIG_ID = "2d418b12-d2e9-41c2-a82c-02db526217e3";

  // const config = {
  //   first_name: user?.firstName,
  //   last_name: user?.lastName,
  //   email: user?.email,
  //   merchant_key: MERCHANT_KEY,
  //   user_ref: user?.email,
  //   is_test: false, //set this to through for a test
  //   config_id: IDENTITYPASS_CONFIG_ID, //optional
  //   callback: (response: any) => {
  //     if (response.code === "00") {
  //       // success
  //       showSuccess(
  //         "Your verification was submitted successfully. You can now go back to the app."
  //       );
  //     } else if (response.code === "01") {
  //       // error
  //       showDanger(
  //         "Your verification was not successful, please try again or contact support"
  //       );
  //     }
  //   },
  // };

  // const verifyWithIdentity = useIdentityPayKYC(config);
  const verifyWithIdentity = () => {};

  // useEffect(() => {
  //   if (user?.firstName) ref.current?.click();
  // }, [user?.firstName]);

  return (
    <div ref={ref}>
      <button onClick={verifyWithIdentity}>Click</button>
    </div>
  );
};

export default MobileVerify;
